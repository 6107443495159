<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin, { postItem } from '@shared/mixins/community';
import PostItemContextMenu from '@shared/components/Community/PostItemContextMenu.vue';

export default {
  mixins: [utilsMixin, communityMixin('post'), postItem],
  components: {
    PostItemContextMenu,
  },
};
</script>

<template>
  <article class="card bradius-0-mobile">
    <header class="card-content p-3 bbottom-grey">
      <div class="media">
        <div class="media-content">
          <h3 class="title is-5 is-size-6-mobile mb-2 has-over is-custom">
            <router-link
              class="has-text-dark"
              :to="{
                name: 'community_group_post',
                params: {postUUID: post.uuid},
              }"
            >
              {{ post.title }}
            </router-link>
          </h3>

          <p class="is-size-8 is-size-9-mobile">
            <router-link
              class="has-text-black70"
              :to="{
                name: 'community_group_post',
                params: {postUUID: post.uuid},
              }"
            >
              <b-tooltip position="is-top">
                <template #content>
                  Publiée le <br>
                  {{ post.published_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                Par <strong>{{ displayName }}</strong>,
                {{ post.published_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </router-link>
          </p>
        </div>
        <nav class="media-right">
          <PostItemContextMenu
            :canManageCommunityEntity="canManageCommunityEntity"
            :post="post"
          />
        </nav>
      </div>
    </header>
    <div class="card-content p-3 bbottom-grey">
      <p class="subtitle is-7">
        {{ excerpt }}
      </p>
      <p v-if="isScheduled || post.pinned" class="tags mb-0">
        <span
          v-if="isScheduled"
          class="tag is-black is-size-9-mobile"
        >
          Programmée
          <b-icon class="ml-2" icon="clock" />
        </span>
        <span
          v-if="post.pinned"
          class="tag is-black is-size-9-mobile"
        >
          Épinglée
          <b-icon class="ml-2" icon="thumbtack" />
        </span>
      </p>
    </div>
    <footer class="card-content p-2 p-1-mobile is-size-9">
      <router-link
        class="has-text-dark"
        :to="{
          name: 'community_group_post',
          params: {postUUID: post.uuid},
        }"
      >
      <b-icon pack="far" icon="comment" /> ({{ post.comments_count }})
      </router-link>
      <template v-if="lastCommentAt">
        <span class="px-2">
          •
        </span>
        <router-link
          :to="{
            name: 'community_group_post',
            params: {postUUID: post.uuid},
          }"
        >
          <b-tooltip position="is-top">
            <template #content>
              Dernière réponse <br>
              le {{ lastCommentAt | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
            </template>
            Dernière réponse
            {{ lastCommentAt | momentFromUTC | moment('from') }}
          </b-tooltip>
        </router-link>
      </template>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.has-over {
  &:hover {
    text-decoration: underline;
  }
}
</style>
