var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"card bradius-0-mobile"},[_c('header',{staticClass:"card-content p-3 bbottom-grey"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_c('h3',{staticClass:"title is-5 is-size-6-mobile mb-2 has-over is-custom"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{
              name: 'community_group_post',
              params: {postUUID: _vm.post.uuid},
            }}},[_vm._v(" "+_vm._s(_vm.post.title)+" ")])],1),_c('p',{staticClass:"is-size-8 is-size-9-mobile"},[_c('router-link',{staticClass:"has-text-black70",attrs:{"to":{
              name: 'community_group_post',
              params: {postUUID: _vm.post.uuid},
            }}},[_c('b-tooltip',{attrs:{"position":"is-top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Publiée le "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.post.published_at),'DD/MM/YY [à] HH:mm'))+" ")]},proxy:true}])},[_vm._v(" Par "),_c('strong',[_vm._v(_vm._s(_vm.displayName))]),_vm._v(", "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.post.published_at),'from'))+" ")])],1)],1)]),_c('nav',{staticClass:"media-right"},[_c('PostItemContextMenu',{attrs:{"canManageCommunityEntity":_vm.canManageCommunityEntity,"post":_vm.post}})],1)])]),_c('div',{staticClass:"card-content p-3 bbottom-grey"},[_c('p',{staticClass:"subtitle is-7"},[_vm._v(" "+_vm._s(_vm.excerpt)+" ")]),(_vm.isScheduled || _vm.post.pinned)?_c('p',{staticClass:"tags mb-0"},[(_vm.isScheduled)?_c('span',{staticClass:"tag is-black is-size-9-mobile"},[_vm._v(" Programmée "),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"clock"}})],1):_vm._e(),(_vm.post.pinned)?_c('span',{staticClass:"tag is-black is-size-9-mobile"},[_vm._v(" Épinglée "),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"thumbtack"}})],1):_vm._e()]):_vm._e()]),_c('footer',{staticClass:"card-content p-2 p-1-mobile is-size-9"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{
        name: 'community_group_post',
        params: {postUUID: _vm.post.uuid},
      }}},[_c('b-icon',{attrs:{"pack":"far","icon":"comment"}}),_vm._v(" ("+_vm._s(_vm.post.comments_count)+") ")],1),(_vm.lastCommentAt)?[_c('span',{staticClass:"px-2"},[_vm._v(" • ")]),_c('router-link',{attrs:{"to":{
          name: 'community_group_post',
          params: {postUUID: _vm.post.uuid},
        }}},[_c('b-tooltip',{attrs:{"position":"is-top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Dernière réponse "),_c('br'),_vm._v(" le "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.lastCommentAt),'DD/MM/YY [à] HH:mm'))+" ")]},proxy:true}],null,false,2825193994)},[_vm._v(" Dernière réponse "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(_vm.lastCommentAt),'from'))+" ")])],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }