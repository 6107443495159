<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { getInfiniteScrollThreshold } from '@shared/services/Utils';
import APIGroup from '@shared/services/API/Community/Group';
import utilsMixin from '@shared/mixins/utils';
import CardPostsFeed from '@shared/components/Community/CardPostsFeed.vue';
import ListPostsFeed from '@shared/components/Community/ListPostsFeed.vue';

export default {
  mixins: [utilsMixin],
  components: {
    CardPostsFeed,
    ListPostsFeed,
  },
  head() {
    return {
      title: 'Discussions',
    };
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    ...mapState('community', {
      currentGroup: 'currentGroup',
      posts: 'currentGroupPosts',
    }),
    wrapper() {
      if (this.currentGroup.type === 'LIST') {
        return 'ListPostsFeed';
      }

      return 'CardPostsFeed';
    },
    canPost() {
      return (
        this.isStoreAdmin
        || !this.currentGroup.settings.readonly
      );
    },
  },
  methods: {
    ...mapMutations('community', {
      setCommunityState: 'setState',
      showPostForm: 'showPostForm',
      incrementGroupCounter: 'incrementGroupCounter',
      decrementGroupCounter: 'decrementGroupCounter',
    }),
    ...mapActions('community', ['loadPosts']),
    onPostDelete(post) {
      this.showAsyncConfirm({
        message: `
          Êtes-vous sûr(e) de vouloir supprimer cette publication&nbsp;?
          <strong>Toutes réponses et médias associés seront définitivement supprimés</strong>.
        `,
      }, ({ done }) => {
        const load = this.$buefy.loading.open();
        return APIGroup.deletePost(post.uuid)
          .then(() => {
            this.posts.data = this.posts.data
              .filter((v) => (v.uuid !== post.uuid));
            this.decrementGroupCounter('posts_count');
            done();
          })
          .finally(() => load.close());
      });
    },
    localLoadPosts(url = null, append = false) {
      if (this.isFetching) {
        return;
      }

      this.isFetching = true;
      this.loadPosts({ url, append })
        .catch((error) => {
          this.scrollFetchErrorsLeft--;
          this.$errorHandlers.axios(error);
        })
        .finally(() => (this.isFetching = false));
    },
    setLoadPostsOnScroll() {
      const onScroll = debounce(() => {
        if (
          getInfiniteScrollThreshold(460)
          && this.posts.links?.next
          && this.scrollFetchErrorsLeft > 0
        ) {
          this.localLoadPosts(this.posts.links.next, true);
        }
      }, 40);

      window.addEventListener('scroll', onScroll);
      this.$on('hook:destroyed', () => window.removeEventListener(
        'scroll', onScroll,
      ));
    },
  },
  created() {
    this.scrollFetchErrorsLeft = 5;
    this.setLoadPostsOnScroll();
  },
  mounted() {
    this.localLoadPosts();
  },
};
</script>

<template>
  <component
    :is="wrapper"
    :canPost="canPost"
    :isFetching="isFetching"
    :posts="posts"
    @form-open="showPostForm()"
    @post-delete="onPostDelete"
  />
</template>
