<script>
import { postsFeed } from '@shared/mixins/community';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import CardPostItem from '@shared/components/Community/CardPostItem.vue';

export default {
  mixins: [postsFeed],
  components: {
    BaseAvatar,
    CardPostItem,
  },
};
</script>

<template>
  <section class="mw-640 mx-auto">
    <nav v-if="canPost" class="box bradius-0-mobile flex-between-center">
      <BaseAvatar
        class="flex-none"
        :avatar="$store.getters['community/userAvatar']"
        :displayName="$store.getters['community/userDisplayName']"
      />
      <button
        class="input ml-3"
        type="button"
        @click="$emit('form-open')"
      >
        Exprimez-vous
      </button>
    </nav>

    <div class="is-relative">
      <CardPostItem
        v-for="post in posts.data"
        :key="post.uuid"
        class="mb-5"
        :post="post"
        @delete="$emit('post-delete', $event)"
      />

      <p
        v-if="!isFetching && posts.data.length <= 0"
        class="box has-text-centered bradius-0-mobile"
      >
        Il ne s'est encore rien passé dans cet espace !
      </p>
      <b-skeleton v-if="isFetching" height="400" />
    </div>
  </section>
</template>
