<script>
import { postsFeed } from '@shared/mixins/community';
import ListPostItem from '@shared/components/Community/ListPostItem.vue';

export default {
  mixins: [postsFeed],
  components: {
    ListPostItem,
  },
};
</script>

<template>
  <section>
    <nav v-if="canPost" class="buttons is-right pr-3-mobile">
      <b-button
        type="is-primary"
        icon-left="folder-plus"
        @click="$emit('form-open')"
      >
        Créer un sujet
      </b-button>
    </nav>

    <div class="is-relative">
      <ListPostItem
        v-for="post in posts.data"
        :key="post.uuid"
        class="mb-5"
        :post="post"
        @delete="$emit('post-delete', $event)"
      />

      <p
        v-if="!isFetching && posts.data.length <= 0"
        class="box has-text-centered"
      >
        Il n'y a pas encore de sujet dans cet espace !
      </p>
      <b-skeleton v-if="isFetching" height="400" />
    </div>
  </section>
</template>
